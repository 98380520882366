/* https://twitter.com/leeerob/status/1345554815158546432 */
@font-face {
  font-family: 'Ilisarniq GTG';
  src: url('/static/fonts/Ilisarniq-Bold.woff2') format('woff2'), url('/static/fonts/Ilisarniq-Bold.woff') format('woff');
  font-style: normal;
  font-display: swap;

  /* font-black */
  font-weight: 900;
}

@font-face {
  font-family: 'Ilisarniq GTG';
  src: url('/static/fonts/Ilisarniq-Demi.woff2') format('woff2'), url('/static/fonts/Ilisarniq-Demi.woff') format('woff');
  font-style: normal;
  font-display: swap;

  /* font-bold */
  font-weight: 700;
}

@font-face {
  font-family: 'Ilisarniq GTG';
  src: url('/static/fonts/Ilisarniq-Regular.woff2') format('woff2'), url('/static/fonts/Ilisarniq-Regular.woff') format('woff');
  font-style: normal;
  font-display: swap;

  /* font-normal */
  font-weight: 400;
}

@font-face {
  font-family: 'Ilisarniq GTG';
  src: url('/static/fonts/Ilisarniq-Light.woff2') format('woff2'), url('/static/fonts/Ilisarniq-Light.woff') format('woff');
  font-style: normal;
  font-display: swap;

  /* font-thin */
  font-weight: 100;
}
