@import './font.css';

/* https://tailwindcss.com/docs/using-with-preprocessors#build-time-imports */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import '@perimetre/ui/dist/postcss/index.css';

/* Our global default css */
